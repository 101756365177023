@import "../../App.scss";

.header-container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  z-index: 10;
  top: 0;
  left: 0;
  height: $header-height;
  width: 100vw;
  background: linear-gradient(to top, $onyx, $onyx-grey);
}

.header-logo {
  max-height: $header-height;
  margin-left: 3rem;
}

.header-logo:active {
  opacity: 0.2;
}

.hamburgerMenu {
  position: absolute;
  height: 2rem;
  width: 2.5rem;
  right: 3rem;
  top: 3rem;
  cursor: pointer;

  .hamburgerBar {
    position: absolute;
    height: 0.3rem;
    width: 2.5rem;
    background-color: white;
    border-radius: 1rem;
    transition: transform 1s, opacity 1s;

    &.top {
      top: 0;

      &.open {
        transform: translateY(0.85rem) rotate(45deg);
      }
    }

    &.mid {
      top: 41%;

      &.open {
        opacity: 0;
      }
    }

    &.bot {
      bottom: 0;

      &.open {
        transform: translateY(-0.85rem) rotate(-45deg);
      }
    }
  }
}

.navbar-container {
  display: flex;
  flex-direction: column;
}

.navbar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 4rem;
  color: $onyx;
  font-weight: bold;
  animation: fadeIn 1s;

    .navContainer {
      position: relative;
      height: 6rem;
      width: 7rem;
      cursor: pointer;

      .navBlock {
        position:relative;
        height: inherit;
        width: inherit;

      .nav {
        position: relative;
        height: inherit;
        width: inherit;
        z-index: 15;
      }

      .navLink {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        text-decoration: none;
        font-family: Avantgarde, TeX Gyre Adventor, URW Gothic L, sans-serif;
        font-weight: bold;
        font-size: 18.2px;
      }

      .navblue,
      .navyellow {
        background-color: $cyan;
        clip-path: polygon(
          0% 98%,
          0.5% 99.5%,
          2% 100%,
          98%100%,
          99.5% 99.5%,
          100% 98%,
          52% 2%,
          50% 0.5%,
          48% 2%
        );
        .navLink {
          bottom: -40px;
          left: 80px;
          transform: translate(-3.6rem, -2.9rem) rotate(0deg);
          margin: auto;
        }
      }

      .navblue:active,
      .navyellow:active,
      .navblue.active,
      .navyellow.active {
        transform: translate(0.8rem, 0.4rem);
      }

      .navyellow {
        background-color: $yellow;
        .navLink {
          transform: translate(-3.6rem, -2.9rem) rotate(0deg);
        }
      }

      .navpink,
      .navblack {
        background-color: $magenta;
        clip-path: polygon(
          0% 2%,
          0.5% 0.5%,
          2% 0%,
          98% 0%,
          99.5% 0.5%,
          100% 2%,
          52% 98%,
          50% 99.5%,
          48% 98%
        );
        .navLink {
          top: -40px;
          left: 50px;
          transform: translate(-1.9rem, 2.9rem) rotate(0deg);
          margin: auto;
        }
      }

      .navpink:active,
      .navblack:active,
      .navpink.active,
      .navblack.active {
        transform: translate(0.4rem, 0.4rem);
      }

      .navblack {
        background-color: $onyx-black;
        .navLink {
          color: $onyx-white;
        }
      }

      .navShadow,
      .navShadowReverse {
        position: absolute;
        top: 0.5rem;
        right: -0.7rem;
        z-index: 14;
        height: 95%;
        width: 95%;
        background-color: #020607aa;
        clip-path: polygon(
          0% 98%,
          0.5% 99.5%,
          2% 100%,
          98%100%,
          99.5% 99.5%,
          100% 98%,
          52% 2%,
          50% 0.5%,
          48% 2%
        );
      }
      .navShadowReverse {
        clip-path: polygon(
          0% 2%,
          0.5% 0.5%,
          2% 0%,
          98% 0%,
          99.5% 0.5%,
          100% 2%,
          52% 98%,
          50% 99.5%,
          48% 98%
        );
        top: 0.4rem;
        right: -0.4rem;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .hamburgerMenu {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .header-container {
    justify-content: flex-start;
    align-items: center;
    .header-logo {
      height: 20vw;
      margin-left: 1rem;
      margin-top: auto;
    }
  }
  .navbar {
    position: absolute;
    display: none;
    top: 0.3rem;
    left: 1rem;
    opacity: 0;
    animation: logoToNav 1s;

    &.open {
      display: flex;
      opacity: 1;
    }
    .navContainer {
      height: 6rem;
      width: 7rem;

      .navLink {
        font-size: 3vw;
      }
    }
  }
  .hamburgerMenu {
    display: flex;
  }
}

@media screen and (max-width: 612px) {
  .hamburgerMenu {
    right: 1rem;
  }

  .navbar{
    max-width: 300px;
    left: 0rem;
  .navContainer{
    height: 3.5rem;
    width: 4.5rem;

    .navShadow {
      height: 4.1rem;
      width: 5rem;
      top: 0.4rem;
      right: -0.8rem;
    }
    .navShadowReverse{
      height: 4.1rem;
      width: 5rem;
      top: .4rem;
      right: -.4rem;
    }
    .nav {
      height: 4.1rem;
      width: 5rem;
      .navLink {
        font-weight: 900;
        font-size: 11px;
        left: 72px !important;
        }
      }
      .navpink,
      .navblack {
        .navLink {
          top: -50px;
          left: 44px !important;
        }
      }
  }
} 
}

.displayBlock {
  display: block;
}

.fadeIn {
  animation: fadeIn 1s;
}
