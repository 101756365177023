.container {
 max-width: 1280px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: auto;
  width: auto;
  height: auto;
  margin-bottom: 5rem;
}

.react-player {
  width: auto;
  margin: auto;
  color: white;
  min-height: 600px;
}

h1 {
  font-style: italic;
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: white;
}

p {
  text-align: center;
  font-size: 24px;
  margin-bottom: 1rem;
}

@media screen and (max-width: 1024px) {
  .react-player {
    // min-height: 400px;
  }
}
@media screen and (max-width: 600px) {
  .container {
    // max-width: 400px;
    position: relative;
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 5rem;
  }
   .react-player {
      width: auto;
      min-height: 150px;
    }
}
