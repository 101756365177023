@import '../../App.scss';

.captcha{
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: auto;
  margin-left: auto;
  // margin: auto;
}

.sendGrid-container{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
 
  margin-bottom: 1rem;
  // padding: 10px 30px;
//   box-shadow: $component-shadow;
//   background: radial-gradient($onyx,$onyx-grey);
  opacity: .9;
  border-radius: 20px;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  color: $onyx-white;
  animation: 2s ease-in fadeIn;

  h1{
    margin: auto;
    font-size: 40px;
    font-weight: 500;
  }

  .custom-box {
    margin: auto;
    border: 1px solid #e2e8f0;
    border-radius: 20px;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
                0 4px 6px -2px rgba(0, 0, 0, 0.05);
    padding: 0.75rem;
    width: 40rem;
    color: $onyx-grey;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    background-color: $onyx;
    font-weight: bold;
  }

  .custom-element {
    width: auto;
  }

  .sendgrid-button {
    display: none;
    background-color: $onyx;
    color: $onyx-white;
    border-radius: 20px;
    font-size: 20px;
    padding: 1rem;
    align-self: center;
    margin-top: .5rem;
  }
  .display{
    display: block;
  }
}

@media screen and (max-width: 1024px) {
  .sendGrid-container{
    .custom-box {
        
        width: 65vw;
    }
  }
}