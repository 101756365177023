.container-instagram {
    width: auto;
    height: auto;
    max-width: 1280px;
    margin: auto;
    display: grid;
    // justify-content: space-evenly;
    grid-template-columns: 1fr 1fr 1fr;
}

.container-instagram * {
    margin: 2rem 2rem 2rem 2rem;
    :hover {
        transform: scale(1.3);
        transition: transform 0.5s;
    }
    max-width: auto;
    // width: 400px;
}

@media screen and (max-width: 1024px) {
    .container-instagram {
        grid-template-columns: 1fr 1fr;
    }
    .container-instagram * {
        width: 300px;
        margin: 1rem 1rem 1rem 1rem;
    }
}

@media screen and (max-width: 612px) {
    .container-instagram {
        grid-template-columns: 1fr;
        max-width: 300px;
    }
    .container-instagram * {
        width: 100%;
        // max-width: 300px;
      margin: 1rem 0rem 1rem 0rem;
    }
}
