@import url("https://fonts.googleapis.com/css?family=Roboto");
body {
  margin: 0;
  font-family: "Roboto";
}

.carousel {
  overflow: hidden;
  width: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.inner {
  white-space: nowrap;
  transition: transform 0.3s;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

.carousel-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 490px;
  background-color: transparent;
}

.carousel-item-text {
  font-size: 1.15rem;
  margin: 40px 0 40px 0;
  padding: 0 20px 0 20px;
  white-space: normal;
  color: #ffffff;
}
.carousel-img {
  margin-right: 30px;
  width: 400px;
  padding: 0 20px 0 20px;
}

/* final stage */

.carousel-buttons {
  // margin: auto;
  // max-width: 100px;
  display: flex;
  justify-content: space-evenly;
}

.button-arrow {
  background: none;
  border: none;
  cursor: pointer;
  margin-top: 20px;
  color: white;
}

.indicators {
  // max-width: 100px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
}

.indicator-buttons {
  border: none;
  cursor: pointer;
}

.indicators > button {
  margin: 5px;
  background: none;
}

.indicator-symbol {
  color: #ffffff;
}

.indicator-symbol-active {
  color: #ffffff;
}


@media screen and (max-width: 1024px) {
  .carousel {
    width: 500px;
  }
  .carousel-item {
    height: 400px;
  }
  .carousel-item-text {
    font-size: 1rem;
  }
  .carousel-img {
    width: 300px;
  }
  
}

@media screen and (max-width: 600px) {
  .carousel {
    width: 350px;
  }
  .carousel-item {
    height: 250px;
  }
  .carousel-item-text {
    font-size: 1.2rem;
    margin-left: -3rem;
  }
  .carousel-img {
    width: 200px;
  }
  .indicators > button {
  margin: 0px;
  background: none;
}
}