@import '../../App.scss';

.popup {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popupBackground {
  position: absolute;
  height:100%;
  width:100%;
  z-index:0;
  background-color: rgb(0, 0, 0, 0.2);
}

.close-btn {
  background: none;
  border: none;
  transition-duration: 0.4s;
.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24
}
}

.close-btn:hover {
  color: white;
}

.popup-inner {
  position: relative;
  z-index:1;
  padding: 32px;
  width: 100%;
  max-width: 800px;
  border-radius: 3cap;
      box-shadow: $component-shadow;
  background: radial-gradient($onyx,$onyx-grey);
//   border-radius: ;
}

.popup-inner .close-btn {
  position: absolute;
  top: 16px;
  right: 16px;
}

@media screen and (max-width: 1024px) {
 .popup-inner {
  position: relative;
  padding: 32px;
  width: 100%;
  max-width: 600px;
  border-radius: 3cap;
  background-color: rgba(128, 124, 124, 0.986);
//   border-radius: ;
}
}

@media screen and (max-width: 600px) {
.popup-inner {
  position: relative;
  padding: 32px;
  width: 100%;
  max-width: 400px;
  border-radius: 3cap;
  background-color: rgba(128, 124, 124, 0.986);
//   border-radius: ;
}
}
