// Début
.main{
    width: auto;
    height: auto;
    margin: auto;
    padding: auto;
    max-width: 1280px;
}

.container{
    display: flex;
}

.p{
    text-align: left;
}

.container-service{
    // margin: auto;
    position: relative;
    justify-content: space-around;
    background-image: url("../../Assets/Services/Fond-imagerie.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    min-height: 600px;
    min-width: 100%;
  
}

.text-imagerie{
    
    position: relative;
    text-align: left;
    color: white;
    font-size: 1.5rem;
    padding: 2rem;
}

.h1{
    text-align: left;
}

.container-repro{
    display: flex;
    position: relative;
    justify-content: space-around;
    margin-top: 2rem;
    width: 100%;
}

.text-repro{
    padding: 2rem;
    color: white;
    text-align: right;
    font-size: 1.5rem;
}

.h2{
    text-align: right;
}
.pic{
    z-index: 0;
    position: relative;
    width: 300px;
    height: 300px;
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
    border-radius: 50%;
    border: 2px solid rgb(134, 132, 132);
    background-color: rgb(134, 132, 132);
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.bandeau{
    z-index: 1;
    margin-top: -1rem;
    height: 40px;
    width: 100%;
    background: url(../../Assets/Pictures/bois.jpg) ;
    background-size: 100%;
}

.pic:hover{
    z-index: 100;
     transform: scale(1.3);
     transition: all .2s ease-in-out;
}

@media screen and (max-width: 1024px) {
  .main {
    width: auto;
    margin: 0 50px 0 50px;
    padding: auto;
    .container-repro{
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: space-around;
        margin-top: 2rem;
        width: 100%;
    }
}
}

@media screen and (max-width: 600px) {
 .main {
    width: auto;
    margin: 0 5px 0 5px;
    padding: auto;
}
}
