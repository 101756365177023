@import "../../App.scss";

.main-container {
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  margin: auto;
  color: white;
  min-height: 100%;
  .item-1 {
    margin-left: 33%;
    z-index: 1;
    position: relative;
    display: flex;
    .inner-1 {
      background-color: $onyx-blue-light-opacity;
      border: 1.2rem solid;
      border-color: white;
      position: relative;
      padding-left: 2rem;
      padding-right: 1rem;
      width: auto;
      height: auto;
      margin: auto;
    }
    .container-arizona {
      z-index: 2;
      position: relative;
      background-color: $onyx-blue;
      // background-color: #44818e;
      width: auto;
      height: auto;
      margin: auto;
      margin-right: -40px;
      border: 1rem solid;
      // padding: 15px;
      border-color: white;
      .arizona {
        position: relative;
        width: auto;
        height: auto;
        padding: 5rem 2rem 3rem 2rem;
        max-width: 400px;
      }
    }
  }
  .paint {
    position: absolute;
    width: 700px;
    height: 700px;
    margin-left: -50rem;
    // margin-top: 0rem;
  }

  .item-2 {
    position: relative;
    margin-top: 3rem;
    z-index: 1;
    .a-propos-text {
      display: flex;
      flex-direction: row;
      background-color: $onyx-blue-light-opacity;
      border: 1rem solid;
      border-color: white;
      color: white;
      padding: 2rem;
      font-weight: bold;
      .logo-cercle {
        position: relative;
        background-color: $onyx-blue;
        width: auto;
        height: auto;
        // margin-left: -10%;
        max-width: 300px;
        max-height: 300px;
        border: 1.2rem solid;
        padding: 4%;
        border-color: white;
        margin: auto;
        // margin-left: 6rem;
      }
    }
  }
  .paint-2 {
    position: absolute;
    width: 700px;
    height: 700px;
    margin-left: 40rem;
    margin-top: 40rem;
    rotate: 180deg;
  }
  .p {
    padding: 3rem;
    font-size: 24px;
  }
}

@media screen and (max-width: 1024px) {
  .main-container {
    min-height: 0vh;
    max-width: auto;
    .item-1 {
      flex-direction: column;
      margin-left: 0%;
      //   margin: auto;
      .inner-1 {
        margin-top: 2rem;
        margin: auto;
      }
      .container-arizona {
        margin-top: 1rem;
        width: fit-content;
        margin: auto;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 2rem;
        .arizona {
          max-width: 500px;
          padding: 4rem 1rem 2rem 1rem;
        }
      }
    }
    .item-2 {
      margin: auto;
      margin-top: 2rem;
      .a-propos-text {
        flex-direction: column;
        .logo-cercle {
          margin-top: 2rem;
          max-width: 200px;
        }
      }
    }

    .paint {
      display: none;
    }
    .paint-2 {
      display: none;
    }
  }
}

@media screen and (max-width: 600px) {
  .main-container {
    max-width: 400px;
    .item-1 {
      flex-direction: column;
      margin-left: none;
      margin: auto;
      max-width: 400px;
      .inner-1 {
        margin-top: 2rem;
        border: 1rem solid;
      }
      .container-arizona {
        border: 1.2rem solid;
        margin-top: 1rem;
        // margin-right: 0rem;

        .arizona {
          max-width: 300px;
          margin: auto;
        }
      }
    }
    .item-2 {
      flex-direction: column;
      margin-top: 2rem;

      .logo-cercle {
        max-width: 250px;
        margin-top: 2rem;
        margin-bottom: 2rem;
        margin: auto;
      }
      .a-propos-text {
        margin-top: 2rem;
      }
    }
    .p {
      padding: 0rem;
    }
  }
}
