.app {
  min-height: 100%;
  max-width: 100vw;
  width: 100%;  
  display: flex;
  flex-direction: row;
  background-color: #fffefe;
}

// .hidden {
//     opacity: 0;
// //     filter: blur(5px);
// //     transform: translate(-100px);
// //     transition: all 1s;
// }

// // @media(prefers-reduced-motion: reduce) {
// //     .hidden {
// //         transition: none;
// //     }
// // }

// .show {
//     opacity: 1;
//     // filter: blur(0);
//     // transform: translate(0);
// }

@import url('https://fonts.googleapis.com/css2?family=Kalam&display=swap');


// Variables
//
// Sizes
//
$header-height: 8rem;
$footer-height: 21rem;
$body-height: calc(100vh - (8rem + 21rem));
$carousel-height: 28rem;
//
//Colors
//
$onyx-blue:		#4c4c4c;
$onyx-blue-opacity:#2b515acc;
$onyx-blue-light:	#4d4b4b;
$onyx-blue-light-opacity:	#bfbfbfcc;
$onyx-black: #000000;
$onyx: #222627;
$onyx-opacity: #222627cc;
$onyx-grey: #b2b6b7;
$onyx-grey-opacity: #b2b6b7cc;
$onyx-white: #e2e6e7;
$cyan: #03bcff;
$magenta: #f50492;
$yellow: #ffff08;
//
//Transitions
//
@keyframes fadeIn {
  from {
      opacity: 0;
      display: flex;
  }

  to {
      opacity: 1;
  }
}

@keyframes logoToNav {
  from{
    transform: scale(0.2) translate(20rem, 5rem);
  }

  to {
      transform: scale(1) translate(0rem, 0rem);
  }
}

@keyframes bottomSlide {
  from {
      transform: translateY(100%);
  }

  to {
      transform: translateY(0%);
  }
}
//
//Others
//
$component-shadow: .5rem .5rem 1rem .2rem $onyx-black;