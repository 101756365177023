@import "../../App.scss";

.item-container {
  align-items: center;
  max-width: 1280px;
  width: auto;
  margin: auto;
  min-height: 1200px;
  display: grid;
  // max-width: 1250px;
  grid-template-columns: repeat(2, 1fr);
}

.pointer {
  cursor: pointer;
  margin: auto;
  flex-grow: 1;
  margin-top: 10vh;
  // margin-left: 10%;
  width: 200px;
  height: 40px;
  position: relative;
}
.pointer:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 20px solid $onyx;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
}

.pointer:hover {
  transform: scale(1.3);
  transition: all 0.2s ease-in-out;
}

.pointe {
  content: "";
  position: absolute;
  right: -20px;
  bottom: 0;
  width: 0;
  height: 0;

  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
}

.first {
  background-color: $cyan;
}
.second {
  background-color: $magenta;
}
.third {
  background-color: $yellow;
}
.fourth {
  background-color: $onyx-black;
}

.titre {
  text-align: center;
  color: white;
  margin-top: 0px;
  font-size: 2rem;
}
.text {
  padding: auto;
  text-align: left;
  color: white;
}

.pics {
  margin: auto;
  z-index: 100;
  cursor: pointer;
}

.pics:hover {
  transform: scale(1.3);
  transition: all 0.2s ease-in-out;
}

@media screen and (max-width: 1024px) {
  .item-container {
    width: auto;
    margin: 0 50px 0 50px;
    padding: auto;
    display: grid;
    gap: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  .pics {
    padding: auto;
    // margin-block: auto;
    // margin: 0 150px;
    width: 250px;
    height: 200px;
  }
  .text {
    width: auto;
    grid-column-start: 1;
    grid-column-end: 3;
    color: white;
    margin: auto;
    // margin: 0 200px;
  }
}

@media screen and (max-width: 600px) {
  .item-container {
    width: auto;
    margin: 0 30px 0 30px;
    padding: auto;
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(1, 1fr);
  }
  .text {
    grid-column-start: 1;
    grid-column-end: 3;
    color: white;
    // margin: 0 200px;
    display: none;
  }
}
