.item-container{
    display: grid;
    gap: 2rem;
}

.item{
   height: 100px;
   width: 600px;
//    background-color: blue;
   .first{
   
   }
   .second{

   }
   .third{

   }
   .fourth{

   }
}