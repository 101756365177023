@import "./App.scss";

.layout {
  margin: auto;
  position: absolute;
  z-index: 0;
  display: flex;
  flex-direction: column;
  z-index: 2;
  justify-content: space-between;
  width: cover;
  height: cover;
  background-color: $onyx;
  animation: 2s ease-in fadeIn;
}

