@import "../../App.scss";

.footer-container {
  bottom: 0;
  left: 0;
  flex-shrink: 0;
  width: 100%;
  z-index: 10;
  color: white;
  height: $footer-height;
  background: linear-gradient(to top, $onyx-grey, $onyx);

  .flexbox-container {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    max-height: 90%;

    .footer-title {
      font-weight: bold;
    }

    .container-main-logo {
      display: flex;
      justify-content: space-around;
    }

    .container-logo {
      max-height: 60px;
    }

    .container-icon {
      max-height: 30px;
    }

    .container-item-1 {
      min-height: 100px;
    }

    .container-item-2 {
      width: 800px;
      height: 250px;
      margin-top: 2rem;
    }
  }

  .copyright {
    text-align: center;
    color: rgba(252, 252, 252, 0.7);
    text-shadow: 0 1px rgba(255, 255, 255, 0.1);
  }
}

@media screen and (max-width: 1100px) {
  .footer-container {
    .copyright {
      text-align: right;
      margin-right: 5rem;
    }
    .flexbox-container {
      .container-item-2 {
        width: 400px;
        height: 200px;
        margin-top: 4rem;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .footer-container {
    height: auto;
    .flexbox-container {
      flex-direction: column;
      align-items: center;
      .container-main-logo {
        flex-direction: row;
      }
      .container-item-2 {
        width: 300px;
        height: 200px;
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
    }
    .copyright {
      text-align: center;
    }
  }
}
